import Logo from "./Logo.png";
import MyPhoto from "./MyPhoto.png";
import MovieApp from "./MovieApp.png";
import Ecommerce from "./Ecommerce.png";
import PokemonApp from "./PokemonApp.png";

const images = {
    Logo,
    MyPhoto,
    MovieApp,
    Ecommerce,
    PokemonApp,
};

export default images;
